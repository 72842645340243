<template>
  <div class="root-div">
    <img :src="imgRaster" @load="onImgRasterLoad" alt="" class="img-raster" ref="imgRaster">


    <canvas :width="resolutions.hidden.width" :height="resolutions.hidden.height" class="canvas hidden" ref="canvasHidden"></canvas>

    <ConvertToPNG :input="'data:image/svg+xml;charset=utf-8,' + renderedSVG" format="dataURL" @output="onImgSVGPNGConvert"/>

    <div class="container-images" :style="{ width: imgContainer.width, height: imgContainer.height }" ref="containerImages">

      <div class="dl-svg-transparent" @click="onDownload"></div>

      <!-- visible canvas so user can manipulate. normally will be smaller than hidden canvas above so that adjustments aren't so computationally intensive -->
      <canvas v-show="!renderedSVGPNGLoaded" :width="resolutions.visible.width" :height="resolutions.visible.height" class="canvas visible" ref="canvasVisible"></canvas>

      <!--  the rendered SVG, converted to PNG
            so it (hopefully) displays properly on all devices -->
      <img v-show="renderedSVGPNGLoaded" :src="renderedSVGPNG" ref="imgPNGVector" class="rendered-svg-png" @load="onImgSVGPNGLoad">

      <div v-show="rendering" class="container-progress">
        <div class="progress">{{percentComplete}}%</div>
      </div>
    </div>

    <div class="status-bar">{{ statusText }}</div>

    <div class="form-element">
      <label class="slider-label" for="#slider-balance">Balance</label>
      <input id="slider-balance" class="slider" v-model="balanceInputValue" type="range" @input="onBalanceInput" @change="onValueChange" min="0" max="100">
    </div>

    <div class="form-element">
      <label class="slider-label" for="#slider-balance">Detail</label>
      <input id="slider-resolution" class="slider" v-model="resolutionInputValue" type="range" @input="onResolutionInput" @change="onValueChange" min="1" max="100">
    </div>

    <div class="form-element">
      <input id="checkbox-invert" type="checkbox" @change="onValueChange" v-model="invertInputValue">
      <label for="#checkbox-invert">Invert</label>
    </div>

    <h2 class="ptv-headline download">3. Download</h2>
    <p class="ptv-paragraph">Tap the button below to download the SVG.</p>
    <div class="ptv-button dl-svg" :class="{ disabled: !renderedSVG }" @click="onDownload">Download</div>
  </div>
</template>

<script>
import Trcbmp from '../assets/trcbmp'
import ConvertToPNG from './ConvertToPNG.vue'

export default {
  name: 'AdjustImage',
  components: {
    ConvertToPNG
  },
  data: function() {
    return {
      canvases: {
        hidden: {
          context: Object(),
          width: Number(),
          height: Number()
        },
        visible: {
          context: Object(),
          width: 1,
          height: 1
        }
      },
      balanceInputValue: 50,
      resolutionInputValue: 100,
      invertInputValue: false,
      imgRasterWidth: 1,
      imgRasterHeight: 1,
      //viewport width
      vpWidth: 1,
      //viewport height
      vpHeight: 1,
      //data URI of rendered SVG
      renderedSVG: String(),
      //rendered SVG in PNG format (for display, otherwise
      //there are problems on mobile with large images)
      renderedSVGPNG: String(),
      //whether rendered SVG in PNG format is loaded or not
      renderedSVGPNGLoaded: false,
      rendering: false,
      lastChangeTime: Number(),
      timer: Number(),
      percentComplete: 0,
      statusText: String()
    }
  },
  computed: {
    //resolutions: the resolutions of the canvases.  these
    //change as the "resolution" value is changed
    resolutions: function() {
      let ch = this.canvases.hidden
      let cv = this.canvases.visible
      let multiplier = this.resolutionInputValue / 100
      let aspect = this.imgRasterWidth / this.imgRasterHeight

      cv.width = Math.min( this.imgRasterWidth, 1024 )
      cv.width = Math.min( this.canvases.visible.width, this.vpWidth )
      cv.height = cv.width / aspect

      return {
        hidden: {
            width: ch.width * multiplier,
            height: ch.height * multiplier
        },
        visible: {
          width: cv.width * multiplier,
          height: cv.height * multiplier
        }
      }
    },

    //imgContainer: must be sized to fit in the viewport and
    //conform to the same aspect ratio as the original image
    //(here css viewport units are used)
    //the logic here is that if the aspect ratio of the viewport
    //is less than the aspect ratio of the image, bound by width.
    //otherwise, bound by height.
    imgContainer: function() {
      let vpAspect = this.vpWidth / this.vpHeight
      let imgAspect = this.imgRasterWidth / this.imgRasterHeight

      let size = { width: Number(), height: Number() }

      if ( vpAspect < imgAspect ) {
        size.width = '60vw'
        size.height = 60 / imgAspect + 'vw'
      }
      else {
        size.height = '60vh'
        size.width = 60 * imgAspect + 'vh'
      }

      return size
    }
  },
  props: {
    imgRaster: String()
  },
  watch: {
    imgRaster: function() {
      this.statusText = 'Loading image...'
      this.renderedSVG = String()
      this.renderedSVGPNG = String()
      this.renderedSVGPNGLoaded = false
    }
  },
  mounted: function() {
    this.canvases.hidden.context = this.$refs.canvasHidden.getContext( '2d' )
    this.canvases.visible.context = this.$refs.canvasVisible.getContext( '2d' )

    this.setViewportSizes()

    window.addEventListener( 'resize', ()=> {
      this.$forceUpdate()
      this.setViewportSizes()
    })
  },
  methods: {
    onImgSVGPNGConvert: function( png ) {
      this.renderedSVGPNG = png
    },
    //onImgSVGPNGLoad
    //when the PNG representation of the rendered image is loaded,
    //we say that rendering has finished.  the progress bar
    //can disappear and we can display the rendered image to the user
    onImgSVGPNGLoad: function() {
      this.renderedSVGPNGLoaded = true
      this.rendering = false
    },
    //onImgRasterLoad
    //when the image that the user selects is loaded,
    //we can paint it to the canvas and begin the first render
    onImgRasterLoad: function() {
      this.imgRasterWidth = this.$refs.imgRaster.naturalWidth
      this.imgRasterHeight = this.$refs.imgRaster.naturalHeight

      this.canvases.hidden.width = this.imgRasterWidth
      this.canvases.hidden.height = this.imgRasterHeight

      this.$nextTick().then( ()=> {
        this.paint( this.canvases.visible )
        this.render()
      } )
    },
    //onDownload
    //when the user wants to download the SVG,
    //we emit the 'file-download' event and let App.vue
    //handle the download
    onDownload: function() {
      if ( !this.renderedSVG ) return
      this.$emit( 'file-download', this.renderedSVG )
    },
    //onBalanceInput
    //called when the user is moving the balance slider
    //but has not stopped moving it and selected and final value
    onBalanceInput: function() {
      this.stopRender()
      this.statusText = 'Balance: ' + this.balanceInputValue + '%'
      this.paint( this.canvases.visible )
    },
    //onBalanceInput
    //called when the user is moving the resolution slider
    //but has not stopped moving it and selected and final value
    onResolutionInput: function() {
      this.stopRender()
      this.statusText = 'Resolution: ' + this.resolutionInputValue + '%'

      //wait for next tick so width and height of the canvas update
      this.$nextTick( function() {
        this.paint( this.canvases.visible )
      } )
    },
    //stopRender
    //stop any current rendering operation,
    //clear any rendered images
    stopRender: function() {
      this.rendering = false
      this.renderedSVGPNGLoaded = false
      this.renderedSVG = String()
      Trcbmp.clear()
    },
    //onValueChange
    //called when the user stops moving the slider and a value
    //is selected.  has some logic so that quick changes to the
    //slider value (like when changing the value with a keyboard)
    //do not invoke the render process with each change
    onValueChange: function() {
      let timeout = 200

      clearTimeout( this.timer )

      if ( Date.now() - this.lastChangeTime < timeout ) {
        this.lastChangeTime = Date.now()
        this.timer = setTimeout( this.onValueChange, timeout )
        return;
      }

      this.lastChangeTime = Date.now()
      this.timer = setTimeout( this.render, timeout )
    },
    //render
    //render the image the user has chosen, with the
    //balance and resolutions settings they have selected
    render: function() {
      this.statusText = 'Initializing Render...'
      this.rendering = true
      this.lastChangeTime = Date.now()
      this.paint( this.canvases.hidden )
      let image = this.$refs.canvasHidden.toDataURL()
      this.percentComplete = 0
      Trcbmp.setCallback( this.svgCallback )
      Trcbmp.loadImageFromUrl( image, this.svgCallback )
    },
    //svgCallback
    //get updates from potrace during the render process
    //so we can update the user on progress, etc.
    svgCallback: function( update ) {
        if ( update.done ) {
          this.statusText = 'Ready for download.'
          this.renderedSVG = Trcbmp.getSVG( 1 )
        }
        else if ( update.progress ) {
          if (update.progress != 100) this.statusText = 'Rendering...' + update.progress + '%'
          else this.statusText = 'Finalizing...'
          this.percentComplete = update.progress
          setTimeout( update.continueProcess, 0 )
        }
        else if ( update.imgLoaded ) {
          setTimeout( Trcbmp.process(), 0 )
        }
        /*
        else if ( update.aborted ) {
        }
        */
    },
    //paint
    //paint the image to a canvas, takes "canvas" as argument,
    //which will be either the "hidden" canvas (full resolution),
    //or the "visible" canvas (preview canvas)
    //uses the balance slider value to draw the image
    //with more or less contrast
    paint: function( canvas ) {
      let threshold = this.balanceInputValue * 2.56

      canvas = canvas || this.canvases.visible

      //FOR LATER:
      //this might be expensive...maybe cache the result of this in a third canvas, or maybe we can use the hidden canvas?
      canvas.context.drawImage( this.$refs.imgRaster, 0, 0, canvas.context.canvas.width, canvas.context.canvas.height )

      let pixels = canvas.context.getImageData( 0, 0, canvas.width, canvas.height )

      let d = pixels.data

      let color1 = this.invertInputValue ? 0 : 255
      let color2 = this.invertInputValue ? 255: 0

      for ( let i = 0; i < d.length; i += 4 ) {
        let red = d[i]
        let green = d[i+1]
        let blue = d[i+2]

        let aboveThreshold = ( 0.2126 * red + 0.7152 * green + 0.0722 * blue ) >= threshold

        d[i] = d[i+1] = d[i+2] = aboveThreshold ? color1 : color2
      }

      canvas.context.putImageData( pixels, 0, 0 )
    },
    //setViewportSizes
    //called when window is resized, and we store the current
    //viewport size
    setViewportSizes: function() {
      this.vpWidth = window.innerWidth
      this.vpHeight = window.innerHeight
    }
  }
}
</script>

<style scoped>
.img-raster {
  display: none;
}

input.slider {
  display: block;
  width: 100%;
  margin: 0 auto;
}

canvas.canvas.visible {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

canvas.canvas.hidden {
  display: none;
}

.container-images {
  background: #fff;
  position: relative;
  border: 1px solid #fff;
  margin: 0 auto;
}

.root-div {
  padding-top: 1em;
}

.container-progress {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba( 0, 0, 0, 0.5 );
}

.progress {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 3em;
  line-height: 3em;
  height: 3em;
  margin: auto;
  color: white;
}

.form-element {
  padding: 0.5em 0;
  width: 80%;
  margin: 0 auto;
}

.form-element .slider-label {
  display: block;
}

.form-element input.slider {
  display: block;
}

.rendered-svg-png {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.dl-svg-transparent {
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.status-bar {
  padding: 0.5em 0;
  color: #ccc;
}

.ptv-headline.download {
    margin-top: 50px;
}
</style>
