<template>
  <div>
    <div class="panel">
      <h1 class="ptv-headline">Convert images to SVG</h1>

      <p class="ptv-paragraph">
        Convert any image into a vector graphic in SVG format.  Choose an image using the button above, and it is immediately converted and prepared for download.  You can adjust balance and detail with a live preview of the image.  Tap the big button above to begin.
      </p>
    </div>

    <div class="panel">
      <div class="examples">
        <h1 class="ptv-headline">Examples</h1>
        <div class="example">
          <img src="../assets/example-01-original.jpg" class="original">
          <img src="../assets/example-01-processed.png" class="processed">
        </div>

        <div class="example">
          <img src="../assets/example-02-original.jpg" class="original">
          <img src="../assets/example-02-processed.png" class="processed">
        </div>

        <div class="example">
          <img src="../assets/example-03-original.jpg" class="original">
          <img src="../assets/example-03-processed.png" class="processed">
        </div>
      </div>
    </div>

    <div class="panel">
      <h3 class="ptv-headline">Adjusting Balance and Detail</h3>

      <p class="ptv-paragraph">
        Move the Detail slider to adjust the resolution of the source image.  Sometimes when converting to vector, a smaller source image results in a better vector image, because unnecessary details (specks, etc) are removed.  Move the Balance slider to adjust the contrast of the image - the balance between the amount of white and black.
      </p>
    </div>
    
    <div class="panel">
      <h3 class="ptv-headline">Reverse Convert (SVG to PNG)</h3>

      <p class="ptv-paragraph">
       The reverse operation works as well!  You can also choose an SVG and immediately start downloading a PNG.
      </p>
    </div>

    <div class="panel footer">
      <a href="mailto:imgvectorapp@gmail.com" class="feedback-link">imgvectorapp@gmail.com</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppInfo'
}
</script>

<style lang="less" scoped>
.example img {
  border: 1px solid #000;
  display: block;
  width: 70px;
  background: #fff;
  margin-bottom: 10px;
}

.example {
  display: inline-block;
}

.example {
  margin-right: 10px;
}

.example:last-child {
  margin-right: 0;
}

@media (min-width: 400px) {
  .example img {
    width: 100px;
  }
}

@media (min-width: 768px) {
  .example img {
    width: 150px;
    border: 2px solid #000;
  }
}

@media (min-width: 1024px) {
  .example img {
    width: 200px;
    border: 2px solid #000;
  }
}

#img-interface {
  width: 80%;
  border: 2px solid #333;
}

@media (min-width: 480px) {
  #img-interface {
    width: auto;
  }
}

.panel {
  padding: 10em 30px 10em 30px;
}

.panel.footer {
    padding: 10em 0 5em 0;
}

.ptv-paragraph {
  text-align: center;
}

.ptv-headline {
  margin-top: 0;
  text-align: center;
}

.panel:nth-child(odd) {
  background: #222;
}

.panel:nth-child(even) {
  background: #333;
}

@media (min-width: 768px) {
  .panel {
    padding: 100px;
  }
}
</style>
