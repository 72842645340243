<template>
  <div>
    <img :src="input" class="hidden" @load="onLoadInput" ref="input">
    <canvas :width="inputWidth" :height="inputHeight" class="hidden" ref="canvas"></canvas>
  </div>
</template>

<script>
export default {
  name: 'ConvertToPNG',
  data: function() {
    return { 
      context: Object(),
      inputWidth: Number(),
      inputHeight: Number()
    }
  },
  props: {
    input: String,
    format: String
  },
  mounted: function() {
    this.context = this.$refs.canvas.getContext( '2d' )
  },
  methods: {
    onLoadInput: function() {
      this.inputWidth = this.$refs.input.naturalWidth
      this.inputHeight = this.$refs.input.naturalHeight

      let w = this.inputWidth
      let h = this.inputHeight

      setTimeout( ()=> {
        this.context.clearRect( 0, 0, w, h )
        this.context.drawImage( this.$refs.input, 0, 0, w, h )

        let c = this.$refs.canvas

        if ( this.format === 'blob' ) {
          c.toBlob( ( blob )=> {
            this.$emit( 'output', blob )
          }, 'image/png', 1 )
        }
        else {
          this.$emit( 'output', c.toDataURL() )
        }
      }, 0 )
    }
  }
}
</script>

<style scoped>
  .hidden {
    display: none;
  }
</style>
