<template>
  <div>
    <label class="file-input-label ptv-button" @drop="onDrop" @dragover="stopEvent">
       Choose image to convert
       <input type="file" class="file-input" @change="onRead">
     </label>
  </div>
</template>

<script>
export default {
  name: 'FileReader',
  methods: {
    stopEvent( evt ) {
      evt.stopPropagation()
      evt.preventDefault()
    },
    onDrop: function( evt ) {
      this.stopEvent( evt )
      this.readFile( evt.dataTransfer.files[ 0 ] )
    },
    onRead: function( eventRead ) {
      this.readFile( eventRead.target.files[ 0 ] )
    },
    readFile( file ) {
      if ( !file ) return

      var fileReader = new FileReader()

      fileReader.onload = ()=> {
        this.$emit( 'file-upload', {
          name: file.name,
          data: fileReader.result
        } )
      }

      fileReader.readAsDataURL( file )
    }
  }
}
</script>

<style lang="less" scoped>
  input.file-input {
    display: none;
  }

  label.file-input-label {
    width: 80%;
    animation: shadow-pulse 3s 1;
  }

  @keyframes shadow-pulse
  {
       0% {
            box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
       }
       100% {
            box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
       }
  }
</style>
