<template>
  <div id="root">

    <section class="title">
      <h1 class="ptv-title">imgvector</h1>
      <h1 class="ptv-sub-title">convert images to vector graphics</h1>

      <svg class="ptv-logo" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid meet" viewBox="133.9390334559012 133.62349130093003 414.1219330881975 374.7530173981399"><defs><path d="M134.94 300.32L198.58 236.68L262.22 300.32L198.58 363.96L134.94 300.32Z" id="bDZbPxiY"></path><path d="M407.18 162.43C444.25 199.5 444.25 259.71 407.18 296.78C370.1 333.85 309.9 333.85 272.82 296.78C235.75 259.71 235.75 199.5 272.82 162.43C309.9 125.35 370.1 125.35 407.18 162.43Z" id="evfMnzK"></path><clipPath id="clipb6Iw3hBA2u"><use xlink:href="#evfMnzK" opacity="1"></use></clipPath><path d="M205.65 371.03L269.29 307.39L332.93 371.03L269.29 434.67L205.65 371.03Z" id="c3jSBl1Y0i"></path><path d="M276.36 441.74L340 378.1L403.64 441.74L340 505.38L276.36 441.74Z" id="c8cDvSLkA"></path><path d="M347.07 371.03L410.71 307.39L474.35 371.03L410.71 434.67L347.07 371.03Z" id="c6pXegoHQ"></path><path d="M417.78 300.32L481.42 236.68L545.06 300.32L481.42 363.96L417.78 300.32Z" id="a11nDv6dlU"></path></defs><g><g><use xlink:href="#bDZbPxiY" opacity="1" fill="#666666" fill-opacity="1"></use></g><g><g clip-path="url(#clipb6Iw3hBA2u)"><use xlink:href="#evfMnzK" opacity="1" fill-opacity="0" stroke="#666666" stroke-width="80" stroke-opacity="1"></use></g></g><g><use xlink:href="#c3jSBl1Y0i" opacity="1" fill="#666666" fill-opacity="1"></use></g><g><use xlink:href="#c8cDvSLkA" opacity="1" fill="#666666" fill-opacity="1"></use></g><g><use xlink:href="#c6pXegoHQ" opacity="1" fill="#666666" fill-opacity="1"></use></g><g><use xlink:href="#a11nDv6dlU" opacity="1" fill="#666666" fill-opacity="1"></use></g></g></svg>
    </section>

    <h2 class="ptv-headline">1. Choose image to convert</h2>
    <p class="ptv-paragraph">Tap the button below to choose a file to read into the system. A sample image has been loaded automatically if you want to simply play with how the app works below.</p>

    <div class="file-upload-container">
      <FileReader v-on:file-upload="onFileRead"/>
    </div>
  </div>
</template>

<script>
import FileReader from './FileReader.vue'

export default {
  name: 'AppHeader',
  components: {
    FileReader
  },
  methods: {
    onFileRead: function( file ) {
      this.$emit( 'file-upload', file );
    }
  }
}
</script>

<style lang="less" scoped>
.ptv-title {
  color: #666;
  text-align: center;
  font-size: 24px;
  margin: 0;
}

.ptv-sub-title {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.ptv-logo {
  width: 100px;
  display: block;
  margin: 30px auto 0 auto;
}

.file-upload-container {
  margin-top: 2em;
  font-size: 12px;
}

section.title {
    background: #fff;
    padding: 10em 0;
}
</style>
