<template>
  <div id="app" class="dark">
    <section id="ptv-header">
      <AppHeader @file-upload="onFileRead"/>
    </section>

    <section id="ptv-adjust" ref="pageElementAdjust">
      <h2 class="ptv-headline adjust">2. Adjust image before download</h2>
      <p class="ptv-paragraph">Adjust balance and detail below. Or download without adjusting by scrolling down and clicking "Download." Sample image already loaded.</p>
      <AdjustImage :imgRaster="loadedImage" @file-download="onFileDownload"/>
    </section>

    <!-- we also support uploading SVGs and converting them to PNG -->
    <ConvertToPNG :input="loadedSVG" format="blob" @output="onPNGConvert"/>

    <section id="ptv-info">
      <AppInfo/>
    </section>

    <canvas ref="canvasSampleImage" v-show="false"></canvas>
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue'
import AdjustImage from './components/AdjustImage.vue'
import AppInfo from './components/AppInfo.vue'
import ConvertToPNG from './components/ConvertToPNG.vue'

var FileSaver = require( 'file-saver' )

export default {
  name: 'app',
  data: function() {
    return {
      loadedImage: String(),
      loadedSVG: String()
    }
  },
  components: {
    AppHeader,
    AdjustImage,
    AppInfo,
    ConvertToPNG
  },
  mounted: function() {
    var ctx = this.$refs.canvasSampleImage.getContext('2d')
    var img = new Image()
    img.onload = () => {
      this.$refs.canvasSampleImage.width = img.naturalWidth
      this.$refs.canvasSampleImage.height = img.naturalHeight
      ctx.drawImage(img, 0, 0)
      this.loadedImage = this.$refs.canvasSampleImage.toDataURL()
    }
    img.src = require('./assets/sample-image.jpg')
  },
  methods: {
    onFileRead: function( file ) {
      if ( typeof file.name !== 'string' ) return

      if ( file.name.substr(-3).toLowerCase() === 'svg' ) {
        this.loadedSVG = file.data
        return
      }

      window.scrollTo({
        top: this.$refs.pageElementAdjust.offsetTop,
        behavior: 'smooth'
      });

      this.loadedImage = file.data
    },
    onFileDownload: function( file ) {
      let blob = new Blob( [ file ], { type: 'image/svg+xml;charset=utf-8' } )
      FileSaver.saveAs( blob, 'vector.svg' )
    },
    onPNGConvert: function( png ) {
      let blob = new Blob( [ png ] )
      FileSaver.saveAs( blob, 'bitmap.png' )
    }
  }
}
</script>

<style lang="less">
@import (css) url('https://fonts.googleapis.com/css?family=Montserrat');

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  background: #333;
  position: relative;
  min-height: 100vh;
}

body {
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  margin: 0;
  font-size: 12px;
}

.ptv-button {
  width: 80%;
  max-width: 300px;
  margin: 0.5em auto;
  padding: 1em 0;
  font-weight: bold;
  margin: 10px auto;
  display: block;
  border: 2px solid #ccc;
  color: #ccc;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  
  &:active {
    background: #666;
  }
}

.ptv-button.disabled {
  color: #ccc;
  cursor: default;
  pointer-events: none;
}

a {
  color: #fff;
  text-decoration: none;
}

a.feedback-link {
  color: #fff;
  font-size: 14px;
}

.ptv-headline {
  font-size: 24px;
  margin: 2em auto 0.5em auto;
  width: 80%;
}

.ptv-paragraph {
  @media (min-width: 480px) { width: 400px; }
  font-size: 14px;
  margin: 0 auto;
  padding: 0 10px 0 10px;
}

#ptv-info {
  margin-top: 10em;
}

#ptv-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 100px auto;
}

#ptv-footer img {
  max-height: 200px;
}

section {
  margin: 0 auto;
}

#img-bg-foot {
  display: block;
  width: 100%;
}

.ptv-headline.adjust {
  margin-top: 100px;
}
</style>
